import * as React from "react"
import PageWrapper from "../components/PageWrapper"

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import * as styles from "../styles/modules/Home.module.css";
import MainSlider from "../components/MainSlider";



const IndexPage = () => {
  


  return (
    <PageWrapper transparent={true}>
      <section className={styles.homePage}>
        <MainSlider></MainSlider>
      </section>
    </PageWrapper>
  )
}




export default IndexPage
