import * as React from "react"
import * as styles from "../styles/modules/Home.module.css"

const GalerieAlbane = ({padded}) => {
    return (
        <div className={styles.galerieAlbane} style={{position: "absolute", right: padded ? 10 : 20, bottom: padded ? 10 : 0, fontSize: ".7rem"}}>
            <p style={{margin: 0, color: "white"}}>Galerie Albane - 1 Rue Suffren, Nantes</p>
        </div>
    )
}

export default GalerieAlbane
