// extracted by mini-css-extract-plugin
export var imageFill = "Home-module--imageFill--OB2-V";
export var imageNoFill = "Home-module--imageNoFill--dL6Ez";
export var simpleLink = "Home-module--simpleLink--eXNw-";
export var actualites = "Home-module--actualites--z2-bW";
export var galerieAlbane = "Home-module--galerieAlbane--MD5lb";
export var laMiniGalerie = "Home-module--laMiniGalerie--drxZe";
export var homePage = "Home-module--homePage--gMDga";
export var Slider = "Home-module--Slider--JxArK";
export var Frame = "Home-module--Frame--MTKw-";
export var FrameImageWrapper = "Home-module--FrameImageWrapper--XizZN";
export var FrameImage = "Home-module--FrameImage--qJnZn";