import * as React from "react"
import LaMiniGalerie from "./LaMiniGalerie"
import Actualites from "./ACTUALITES"
import GalerieAlbane from "./GalerieAlbane"

const SliderAside = ({color, type, padded}) => {
    const getElem = (aType) => {
        if (aType === "mini-galerie") {
            return <LaMiniGalerie padded={padded}/>
        }
        if (aType === "galerie-albane") {
            return <GalerieAlbane padded={padded}/>
        }
        if (aType === "none") {
            return <></>
        }
    }
    return (
        <div style={{width: "30%", height: "100%", position: "absolute", right: 0, zIndex: 10}}>
            <Actualites colour={color} padded={padded}/>
            {
                getElem(type)
            }
        </div>
    )
}

export default SliderAside
