import * as React from "react"
import * as styles from "../styles/modules/Home.module.css"

const Actualites = ({colour, padded}) => {
    return (
        <div className={styles.actualites} style={{fontSize: 14.5, position: "absolute", right: padded ? 20 : 0, top: padded ? 0 : -20, transformOrigin: "100% 100%", transform: "rotate(-90deg)"}}>
          <span style={{color: colour === "white" ? colour : "black"}}>ACTUALITÉ <span style={{color: colour === "red" ? "#AE0018" : "white"}}>2021</span></span>  
        </div>
    )
}

export default Actualites
